<template>
  <div class="login">
    <div class="login-inner">
      <lang-switcher />
      <div class="login-inner-form">
        <div class="logo">
          <img src="/img/elixio-connect.svg" alt="icon" />
        </div>
        <div class="form-inner" v-if="!is_2fa_enabled">
          <h1>{{ $t("COMMON.SIGN_IN") }}</h1>
          <p>{{ $t("COMMON.SIGN_IN_MESSAGE") }}</p>

          <div class="error-show" v-if="error">
            <base-alert dismissible type="danger" icon="fa fa-exclamation">
              {{ error }}
            </base-alert>
          </div>

          <form role="form" @submit.prevent="handleSubmit()">
            <base-input
              name="Email"
              :placeholder="$t('COMMON.EMAIL')"
              label="Email"
              v-model="email"
            />
            <validation-error :errors="apiValidationErrors.email" />

            <base-input
              name="Password"
              type="password"
              :placeholder="$t('COMMON.PASSWORD')"
              label="Mot de passe"
              v-model="password"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.password" />

            <div class="submit">
              <base-button type="primary" native-type="submit">
                {{ $t("COMMON.SIGN_IN") }}
              </base-button>
            </div>

            <div class="password-forget">
              <a href="/password/reset">
                <span>{{ $t("COMMON.FORGOT_PASSWORD") }}</span>
              </a>
            </div>
          </form>
        </div>
        <div class="form-inner" v-if="is_2fa_enabled">
          <form role="form" @submit.prevent="handleSubmitCodeVerification()">
            <base-input
              name="Password"
              type="text"
              :placeholder="$t('COMMON.VERIFICATION_CODE')"
              v-model="code"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.code" />

            <div v-if="error">
              <base-alert dismissible type="danger" icon="fa fa-exclamation">
                {{ error }}
              </base-alert>
            </div>
            <div class="send-code">
              <span @click="handleSubmit">
                <small>{{ $t("COMMON.RESEND_CODE") }}</small>
              </span>
            </div>

            <div class="submit">
              <base-button type="primary" native-type="submit">
                {{ $t("COMMON.SIGN_IN") }}
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { LangSwitcher } from "@/components";

export default {
  components: {
    ValidationError,
    LangSwitcher,
  },
  mixins: [formMixin],
  data: () => ({
    error: null,
    code: null,
    is_2fa_enabled: false,
    email: "",
    password: "",
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Locale: this.$i18n.locale,
        },
      };

      try {
        const response = await this.$store.dispatch("login", {
          user,
          requestOptions,
        });
        if (!!response?.success) {
          this.is_2fa_enabled = !!response?.success;
          this.$notify({
            type: "info",
            //position: 'bottom-right',
            message: response?.message,
          });
        }
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0].detail;
        this.$notify({
          type: "danger",
          message: error?.response?.data?.errors?.[0].detail,
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },

    async handleSubmitCodeVerification() {
      this.error = null;
      const data = {
        data: {
          type: "users",
          attributes: {
            code: this.code,
          },
        },
      };

      try {
        await this.$store.dispatch("users/code_verification", data);
        this.$notify({
          type: "success",
          message: this.$t("COMMON.SUCCESS_VERIFICATION"),
        });
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0]?.detail;
        this.$notify({
          type: "danger",
          message: this.$t("COMMON.ERROR_VERIFICATION"),
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },
  },
};
</script>
